import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { apiLogin } from '../../services/api/accounts/Login';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import styles from './Login.module.css';

const LoginComponent = () => {
    const { register, handleSubmit, setError, formState } = useForm();
    const [validationMessage, setValidationMessage] = useState('');

    const onSubmit = async (data) => {
        let result = await apiLogin.post(data);

        console.log(result);

        if (result.error) {
            setValidationMessage(result.error);
            console.error(result.error);
        } else {
            localStorage.setItem('token', result.token);
            localStorage.setItem('name', result.fullName);
            localStorage.setItem('shopId', result.shopId);

            window.location.href = result.companyInfoNeedToFill ? 'account' : '/';
        }
    };

    return (
        <Grid container>
            <Grid item xs={8}>
                <div className={styles.LoginBackground}></div>
            </Grid>
            <Grid item xs={4}>
                <Container component="main" maxWidth="sm" className={`${styles.LoginContainer} height100`}>
                    <Grid container direction="column">
                        <a className={styles.LogoSmall}></a>
                        <Grid>
                            <form onSubmit={handleSubmit(onSubmit)} className={styles.LoginForm}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <h1>Zaloguj się</h1>
                                    </Grid>
                                    <Grid item xs={12} className="text-center">
                                        <span className="text-danger">{validationMessage}</span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Email" fullWidth required autoFocus {...register("email", { required: true })} /><br />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Hasło" fullWidth required type="password" name="password" {...register("password", { required: true })} /><br />
                                    </Grid>
                                    <Grid item xs={12} className={styles.LoginForgotGrid}>
                                        <Link to="/remind-password" className={styles.LoginForgot}>Nie pamiętasz hasła?</Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="submit" className={styles.LoginButton}>Zaloguj</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default LoginComponent;