import React, { Component } from "react";
import { confirm } from "react-confirm-box";
import { confirmOptions } from '../../services/common/Options';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    TablePagination
} from "@material-ui/core";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { apiCoupon } from "../../services/api/coupon/Coupon";
import tableStyles from '../shared/table/Table.module.css';
import { Search, SearchIconWrapper, StyledInputBase, StyledInputDate, StyledInputNumber } from "../shared/formControls/Filters";
import TableHead from "../shared/table/TableHeadComponent";
import CreateCoupon from "./CreateCoupon";
import { couponTypes } from '../../configurations/coupon/couponTypes';

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'ID',
        width: '5%'
    },
    {
        id: 'code',
        numeric: false,
        disablePadding: true,
        label: 'Treść kodu'
    },
    {
        id: 'discountAmount',
        numeric: false,
        disablePadding: true,
        label: 'Kwota zniżki'
    },
    {
        id: 'validityPeriod',
        numeric: false,
        disablePadding: true,
        label: 'Okres aktywności'
    },
    {
        id: 'limit',
        numeric: true,
        disablePadding: true,
        label: 'Limit'
    },
    {
        id: 'products',
        numeric: false,
        disablePadding: true,
        label: 'Produkty'
    },
]

class Coupons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'asc',
            orderBy: 'Id',
            search: '',
            discountFrom: '',
            discountTo: '',
            validityPeriodFrom: '',
            validityPeriodTo: '',
            selected: [],
            page: 0,
            rowsPerPage: 5,
            rows: [],
            size: 0
        };

        this.timer = null;

        this.loadData();
    }

    loadData = () => {
        clearTimeout(this.timer);

        let query = {
            orderBy: this.state.orderBy,
            orderDestination: this.state.order.toUpperCase(),
            start: this.state.page * this.state.rowsPerPage,
            size: this.state.rowsPerPage,
            search: this.state.search,
            discountAmountFrom: this.state.discountFrom,
            discountAmountTo: this.state.discountTo,
            validityPeriodFrom: this.state.validityPeriodFrom,
            validityPeriodTo: this.state.validityPeriodTo
        };

        this.timer = setTimeout(async () => {
            let coupons = await apiCoupon.getList(query);
            this.setState({
                rows: coupons.items ?? [],
                size: coupons.length ?? 0,
                selected: []
            });
        }, 500);
    };

    clearFilters = () => {
        this.setState({
            search: '',
            discountFrom: '',
            discountTo: '',
            validityPeriodFrom: '',
            validityPeriodTo: ''
        }, this.loadData);
    };

    handleCreated = () => this.loadData();

    handleDelete = async (id) => {
        const result = await confirm("Czy na pewno chcesz usunąć wybrany wiersz?", confirmOptions);

        if (result) {
            await apiCoupon.remove(id);
            this.loadData();
        }
    };

    handleDeleteSelected = async () => {
        const result = await confirm("Czy na pewno chcesz usunąć wybrane wiersze?", confirmOptions);

        if (result) {
            await apiCoupon.removeMany(this.state.selected);
            this.loadData();
        }
    };

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = this.state.rows.map((n) => n.id);
            // this.state.selected = newSelecteds;
            this.setState({ selected: newSelecteds });
            return;
        }
        this.setState({ selected: [] });
    };

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';

        this.state.order = property == this.state.orderBy && this.state.order != 'desc' ? 'desc' : 'asc';
        this.state.orderBy = property;

        this.loadData();
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        }, this.loadData);
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }, this.loadData);
    };

    handleCheckboxChange = (id, isChecked) => {
        if (isChecked)  {
            this.state.selected.push(id);
            this.setState({
                selected: this.state.selected
            });
        } else {
            this.setState({
                selected: this.state.selected.filter((item) => item !== id)
            });
        }
    };

    labelDisplayedRows = ({ from, to, count }) => {
        return `${from}–${to} z ${count !== -1 ? count : `więcej niż ${to}`}`;
    };

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    render() {
        return (
            <Grid>
                <Grid container spacing={1} className={tableStyles.TableMenu}>
                    <Grid item md={12} className="d-flex align-items-baseline">
                        <h1 style={{flex: '0 1 140px'}}>Kody zniżkowe</h1>
                        <CreateCoupon onCreated={this.handleCreated} editMode={false} />
                        <div style={{flex: '0 1 200px'}}>
                            <span className={tableStyles.Label}>Akcje grupowe</span>
                            <Button className={tableStyles.Button} startIcon={<DeleteOutlineIcon />} onClick={this.handleDeleteSelected}>
                                Usuń
                            </Button>
                        </div>
                        <Search className={tableStyles.Search}>
                            <SearchIconWrapper>
                            <SearchIcon className={tableStyles.Icon}/>
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Szukaj..."
                                inputProps={{ 'aria-label': 'search' }}
                                value={this.state.search}
                                onChange={(event) => {
                                    this.setState({
                                        search: event.target.value
                                    }, () => {
                                        if (this.state.search.length >= 2 || this.state.search.length == 0)
                                            this.loadData();
                                    });
                                }}
                            />
                        </Search>
                        <div style={{flex: '0 1 240px'}}>
                            <span className={tableStyles.Label}>Kwota zniżki:</span>
                            <StyledInputNumber
                                placeholder="Od"
                                inputProps={{ 'aria-label': 'search', 'type': 'number' }}
                                value={this.state.discountFrom}
                                onChange={(event) => {
                                    this.setState({
                                        discountFrom: event.target.value
                                    }, this.loadData);
                                }}
                            />
                            <StyledInputNumber
                                placeholder="Do"
                                inputProps={{ 'aria-label': 'search', 'type': 'number' }}
                                value={this.state.discountTo}
                                onChange={(event) => {
                                    this.setState({
                                        discountTo: event.target.value
                                    }, this.loadData);
                                }}
                            />
                        </div>
                        <div style={{flex: '0 1 350px'}}>
                            <span className={tableStyles.Label}>Okres aktywności:</span>
                            <StyledInputDate
                                placeholder="Od"
                                inputProps={{ 'aria-label': 'search', 'type': 'date' }}
                                value={this.state.validityPeriodFrom}
                                onChange={(event) => {
                                    this.setState({
                                        validityPeriodFrom: event.target.value
                                    }, this.loadData);
                                }}
                            />
                            <StyledInputDate
                                placeholder="Do"
                                inputProps={{ 'aria-label': 'search', 'type': 'date' }}
                                value={this.state.validityPeriodTo}
                                onChange={(event) => {
                                    this.setState({
                                        validityPeriodTo: event.target.value
                                    }, this.loadData);
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item md={12} className="d-flex justify-content-end">
                        <Button className={tableStyles.Button} startIcon={<CloseIcon />} onClick={this.clearFilters}>
                            Wyczyść filtry
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table className={tableStyles.Table}>
                                <TableHead
                                    headCells={headCells}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    numSelected={this.state.selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={this.state.size}
                                    canSelectAll
                                />
                                <TableBody>
                                    {this.state.rows.map((row, index) => {
                                        const isItemSelected = this.isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow key={row.id} className={tableStyles.Row}>
                                                <TableCell padding="checkbox" className={tableStyles.CheckboxCell}>
                                                    <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} onChange={(event) => {
                                                        this.handleCheckboxChange(row.id, event.target.checked);
                                                    }}/>
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={`${tableStyles.ValueCell} ${tableStyles.ValueCellFirst}`}>
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.code}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.discountAmount} {couponTypes[row.type].symbol}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.validityPeriodFrom} - {row.validityPeriodTo}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.limit}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.products}
                                                </TableCell>
                                                <TableCell align="right" padding="none" className={tableStyles.CrudCell}>
                                                    <CreateCoupon onCreated={this.handleCreated} editMode={true} couponId={row.id} />
                                                    <DeleteOutlineIcon role="button" className={tableStyles.Button} onClick={async () => this.handleDelete(row.id)}/>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.size}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            labelDisplayedRows={this.labelDisplayedRows}
                            labelRowsPerPage={"Wierszy na stronę:"}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
    };
}

export default Coupons;