import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
    Button,
    Menu,
    MenuItem,
    InputBase} from "@material-ui/core";
import tableStyles from '../../shared/table/Table.module.css';

function CategoryFilterMenu(props) {
    const {
        selected,
        categories,
        onUpdated
    } = props;

    // const [selected, setSelected] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChangeCategory = async (category) => {
        // setSelected(category);
        onUpdated(category ?? '');
        handleClose();
    }

    return (
      <div>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={tableStyles.Button}
        >
          {selected ? selected.name : 'Wybierz'}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
            <MenuItem onClick={async () => await handleChangeCategory(null)}>Wyczyść</MenuItem>
            {categories?.map((category, index) => <MenuItem key={index} onClick={async () => await handleChangeCategory(category)}>{category.name}</MenuItem>)}
        </Menu>
      </div>
    );
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  flex: '1 1 auto'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
}));

const StyledInputNumber = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: 0,
        transition: theme.transitions.create('width'),
        width: '60px'
    },
}));

const StyledInputDate = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: 0,
      transition: theme.transitions.create('width'),
      width: '100px'
  },
}));

export {
    CategoryFilterMenu,
    Search,
    SearchIconWrapper,
    StyledInputBase,
    StyledInputNumber,
    StyledInputDate
}