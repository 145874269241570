const adjustDate = v => {
    if (isNaN(v)) return;
  
    let parsedDate = new Date(v);
    let adjustedDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);
  
    return adjustedDate;
};

export {
    adjustDate
}