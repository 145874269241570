import React, { Component } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Menu,
  MenuItem,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  Paper
} from "@material-ui/core";
import tableStyles from './shared/table/Table.module.css';

import OrdersIcon from '../images/orders_list_icon.png';
import ProductsIcon from '../images/product_list_icon.png';
import CouponsIcon from '../images/coupons_list_icon.png';
import AccountIcon from '../images/account_icon.png';
import { Link } from 'react-router-dom';

export class Home extends Component {

  render () {
    return (
      <Grid>
        <Grid container spacing={1} className={tableStyles.TableMenu}>
          <Grid item md={2}>
            <h1>Pulpit</h1>
          </Grid>
        </Grid>
        <Grid container spacing={5} className="dashboard-container">
          <Grid item md={3}>
            <Link to="/orders">
              <Paper className="dashboard-box" variant="outlined" elevation={3}>
                <h2>Lista zamówień</h2>
                <img src={OrdersIcon} alt="" />
              </Paper>
            </Link>
          </Grid>
          <Grid item md={3}>
            <Link to="/products">
              <Paper className="dashboard-box" variant="outlined" elevation={3}>
                <h2>Lista produktów</h2>
                <img src={ProductsIcon} alt="" />
              </Paper>
            </Link>
          </Grid>
          <Grid item md={3}>
            <Link to="/coupons">
              <Paper className="dashboard-box" variant="outlined" elevation={3}>
                <h2>Kody zniżkowe</h2>
                <img src={CouponsIcon} alt="" />
              </Paper>
            </Link>
          </Grid>
          <Grid item md={3}>
            <Link to="/account">
              <Paper className="dashboard-box" variant="outlined" elevation={3}>
                <h2>Twój profil</h2>
                <img src={AccountIcon} alt="" />
              </Paper>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={3} />
          <Grid item md={6} className="request-machine-connect">
            <hr />
            <h3>Zgłoś chęć podpięcia się do danej maszyny</h3>
            <a href="mailto:office@coblit.com" className="button filled-button">Poproś o ofertę</a>
          </Grid>
          <Grid item md={3} />
        </Grid>
      </Grid>
    );
  }
}
