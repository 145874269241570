import React from 'react';
import PropTypes from 'prop-types';
import { apiOrder } from '../../services/api/order/Order';
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@mui/material';
import css from './Orders.module.css';
import TableHead from '../shared/table/TableHeadComponent';
import _ from 'lodash';
import { ArrowBack } from '@material-ui/icons';

const positionsTableHead = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Produkt'
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: true,
        label: 'Ilość'
    },
    {
        id: 'netPrice',
        numeric: true,
        disablePadding: true,
        label: 'Cena netto'
    },
    {
        id: 'vat',
        numeric: true,
        disablePadding: true,
        label: 'Stawka VAT'
    },
    {
        id: 'grosPrice',
        numeric: true,
        disablePadding: true,
        label: 'Cena brutto'
    },
    {
        id: 'positionPrice',
        numeric: true,
        disablePadding: true,
        label: 'Kwota brutto'
    },
    {
        id: 'message',
        numeric: false,
        disablePadding: true,
        label: 'Wiadomość od sprzedającego'
    },
]

const statuses = [
    {
        id: 0,
        text: 'Przyjęto zamówienie',
        color: '#FC9B00'
    },
    {
        id: 1,
        text: 'W trakcie realizacji',
        color: '#FC9B00'
    },
    {
        id: 2,
        text: 'Gotowe do wydania',
        color: '#FC9B00'
    },
    {
        id: 3,
        text: 'Gotowe do odbioru',
        color: '#FC9B00'
    },
    {
        id: 4,
        text: 'Wysłane',
        color: '#FC9B00'
    },
    {
        id: 5,
        text: 'Zakończone',
        color: '#FC9B00'
    },
    {
        id: 6,
        text: 'Anulowane',
        color: '#FC9B00'
    },
]

function OrderDetails(props) {
    const {
        onBackClick,
        onChange,
    } = props;
    const [order, setOrder] = React.useState(props.order);

    const setStatus = async (status) => {
        let model = await apiOrder.postCustom('/setStatus', { id: order.id, status})
        setOrder(model?.order);

        onChange();
    }

    return (
        <div className={`d-flex flex-row align-items-baseline`}>
            <div className="d-flex flex-column align-items-center">
                <Checkbox className={css.OrderCheckbox}/>
                <div title="Wróć">
                    <ArrowBack className="mt-2 cursor-pointer" onClick={onBackClick} />
                </div>
            </div>
            <div className={css.OrderDetailsBox}>
                <table className={css.Data}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Zamawiający</th>
                            <th>Metoda dostawy</th>
                            <th>Dane dostawy</th>
                            <th>Dane faktury</th>
                            <th>Metoda płatności</th>
                            <th>Użyte vouchery</th>
                            <th>Całkowita kwota brutto</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{order.id}</td>
                            <td>{order.name} {order.surname}</td>
                            <td>{order.deliveryMethod ?? '-'}</td>
                            <td>
                                {order.customer
                                    ? <>
                                        {order.customer.name} {order.customer.surname} <br/>
                                        <small>ul. {order.customer.street} {order.customer.houseNumber}{(order.customer.doorsNumber) ? `/${order.customer.doorsNumber}` : ''}</small> <br/>
                                        <small>{order.customer.postalCode} {order.customer.city}</small> <br/>
                                        <small>{order.customer.phoneNumber}</small>
                                    </>
                                    : '-'
                                }
                            </td>
                            <td>
                                {order.company
                                    ?   <>
                                            {order.company.companyName} <br/>
                                            <small>ul. {order.company.street} {order.company.houseNumber}{(order.company.doorsNumber) ? `/${order.company.doorsNumber}` : ''}</small> <br/>
                                            <small>{order.company.postalCode} {order.company.city}</small> <br/>
                                            <small>{order.company.nip}</small>
                                        </>
                                    : '-'
                                }
                            </td>
                            <td>{order.paymentMethod ?? '-'}</td>
                            <td>{order.discountCode ?? '-'}</td>
                            <td>{parseFloat(order.total).toFixed(2).toString().replace('.',',')} zł</td>
                        </tr>
                    </tbody>
                </table>
                <Table className={css.Positions}>
                    <TableHead
                        headCells={positionsTableHead}
                        order={'ASC'}
                        orderBy={'name'}
                        onRequestSort={() => {}}
                    />
                    <TableBody>
                        {order.positions.map((position, index) =>
                            <TableRow key={index}>
                                <TableCell>{position.name}</TableCell>
                                <TableCell>{position.quantity} {position.unit}</TableCell>
                                <TableCell>{parseFloat(position.netPrice).toFixed(2).toString().replace('.',',')} zł</TableCell>
                                <TableCell>{position.vat} %</TableCell>
                                <TableCell>{parseFloat(position.grosPrice).toFixed(2).toString().replace('.',',')} zł</TableCell>
                                <TableCell>{parseFloat(position.positionPrice).toFixed(2).toString().replace('.',',')} zł</TableCell>
                                <TableCell>{position.message ?? '-'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <div className={css.StatusTimeline}>
                    {statuses.map((status, index) => {
                        let statusLogDate = _.find(order.log, (l) => l.status === status.id);
                        let current = (status.id === order.status);

                        return (
                            <div key={index} className={`${css.StatusPoint} ${current ? css.Active : ''} ${(!statusLogDate) ? css.InActive : ''}`}>
                                <div className="d-flex flex-column w-100">
                                    <div className={css.Timeline}>
                                        <div className={css.Dot} />
                                        {index !== statuses.length - 1 &&
                                            <div className={css.Line}>
                                                <hr />
                                            </div>
                                        }
                                    </div>
                                    <span className={css.StatusName}>{status.text}</span>
                                    <span className={css.StatusLog}>{statusLogDate?.timestamp}</span>
                                </div>
                                {current
                                    ? <button className={`${css.ButtonSet} ${css.Currently}`} disabled>Ustawione</button>
                                    : <button className={css.ButtonSet} onClick={() => setStatus(status.id)}>Ustaw</button>
                                }
                            </div>
                        )
                    })}
                    <button className={css.Back} onClick={onBackClick}>Zwiń</button>
                </div>
            </div>
        </div>
    )
}

OrderDetails.propTypes = {
    order: PropTypes.object.isRequired,
    onBackClick: PropTypes.func.isRequired
};

export default OrderDetails;