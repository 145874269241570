import { apiProvider } from './Provider';

export class ApiCore {
    constructor(options) {
        if (options.get) {
            this.get = async () => {
                return await apiProvider.get(options.url);
            };
        }

        if (options.getSingle) {
            this.getSingle = async (id) => {
                return await apiProvider.getSingle(options.url, id);
            };
        }

        if (options.getCustom) {
            this.getCustom = async (customUrl) => {
                return await apiProvider.get(options.url + customUrl);
            };
        }

        if (options.getList) {
            this.getList = async (query) => {
                return await apiProvider.getList(options.url, query);
            }
        }

        if (options.search) {
            this.search = async (search) => {
                return await apiProvider.search(options.url, search);
            };
        }

        if (options.post) {
            this.post = async (model) => {
                return await apiProvider.post(options.url, model);
            };
        }

        if (options.postCustom) {
            this.postCustom = async (customUrl, model) => {
                return await apiProvider.post(options.url + customUrl, model);
            };
        }

        if (options.put) {
            this.put = async (id, model) => {
                return await apiProvider.put(options.url + '/' + id, model);
            };
        }

        if (options.putCustom) {
            this.putCustom = async (customUrl, model) => {
                return await apiProvider.put(options.url + customUrl, model);
            }
        }

        if (options.remove) {
            this.remove = async (id) => {
                return await apiProvider.remove(options.url, id);
            };
        }

        if (options.removeMany) {
            this.removeMany = async (ids) => {
                return await apiProvider.removeMany(options.url, ids);
            }
        }

        if (options.changeStatus) {
            this.changeStatus = async (ids, status) => {
                return await apiProvider.changeStatus(options.url, { ids, status });
            }
        }
    }
}

export default ApiCore;