import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const MainTextField = styled(TextField)({
    '& input:valid:focus + fieldset': {
        borderColor: '#09cab3'
    },
    '& label.Mui-focused': {
        color: '#09cab3',
    }
});

export default MainTextField;