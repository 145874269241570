import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { apiProductCategory } from '../../services/api/product/Category';

import TextField from '../shared/formControls/TextField'
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import tableStyles from '../shared/table/Table.module.css';

const CreateProductCategoryDialog = (props) => {
    const {
        onCreated,
        editMode,
        id
    } = props;

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [open, setOpen] = React.useState(false);
    const [formRef, setFormRef] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [isFileValid, setIsFileValid] = React.useState(true);
    const [existedImage, setExistedImage] = React.useState('');

    const handleClickOpen = async () => {
        setOpen(true);

        if (editMode) {
            let data = await apiProductCategory.getSingle(id);

            if (data.category) {
                setExistedImage(data.category.iconPath);
                reset({
                    name: data.category.name,
                });
            }
        }
    }

    const handleClose = () => {
        reset();
        setExistedImage('');
        setSelectedFile(null);
        setIsFileValid(true);
        setOpen(false);
    }

    const submitForm = () => {
        validateFile();
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }

    const handleFormSubmit = async (data) => {
        if (!validateFile())
            return;

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('icon', selectedFile);

        let result = editMode ? await apiProductCategory.put(id, formData)
                              : await apiProductCategory.post(formData);

        if (result) {
            onCreated();
            handleClose();
        }
        else {
            alert("Błąd");
        }
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const validateFile = () => {
        if (!editMode && !selectedFile) {
            setIsFileValid(false);
            return false;
        }

        setIsFileValid(true);
        return true;
    }

    return (
        <div className="d-inline">
            {
                editMode
                ? <EditIcon role="button" className={tableStyles.Button} onClick={handleClickOpen} />
                : <button className="btn btn-link" onClick={handleClickOpen}>Dodaj nową</button>
            }
            <Dialog open={open} onClose={handleClose}>
                {
                    editMode
                    ? <DialogTitle>Edytuj kategorię</DialogTitle>
                    : <DialogTitle>Dodaj kategorię</DialogTitle>
                }
                <DialogContent>
                    <form ref={ref => setFormRef(ref)} onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item md={12} className="modified-form">
                                <TextField
                                    required
                                    helperText={<span className="text-danger">{errors?.name?.message}</span>}
                                    className={(errors?.name) ? 'with-error' : ''}
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Nazwa"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    {...register("name", { required: 'To pole jest wymagane' })} />
                            </Grid>
                            <Grid item md={12} className={`file-upload-input ${(!isFileValid) ? 'invalid' : ''}`}>
                                <input
                                    type="file"
                                    className="form-control file-with-background"
                                    multiple=""
                                    onChange={handleFileChange}
                                    style={(editMode) ? {
                                        backgroundImage: `url(../${existedImage})`
                                    } : null}
                                />
                                <small>Preferowana wielkość zdjęcia to 80px szerokości i 80px wysokości</small>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className="button filled-button" onClick={handleClose}>Anuluj</Button>
                    <Button className="button outline-button" onClick={submitForm}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CreateProductCategoryDialog.propTypes = {
    onCreated: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    id: PropTypes.number
};

export default CreateProductCategoryDialog;