import ApiCore from '../utilities/ApiCore';

export const apiOrder = new ApiCore({
    getSingle: true,
    getList: true,
    getCustom: true,
    search: false,
    post: true,
    postCustom: true,
    put: true,
    putCustom: true,
    changeStatus: true,
    url: 'order',
    plural: 'order',
    single: 'order'
});