export const couponTypes = [
    {
        id: 0,
        name: 'Procentowy na produkt',
        symbol: '%'
    },
    {
        id: 1,
        name: 'Procentowy na koszyk',
        symbol: '%'
    },
    {
        id: 2,
        name: 'Kwotowy na produkt',
        symbol: 'zł'
    },
    {
        id: 3,
        name: 'Kwotowy na koszyk',
        symbol: 'zł'
    }
];