import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
// import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import Checkbox from '@mui/material/Checkbox';

import {
    Box,
    Checkbox,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel
} from "@material-ui/core";

import { visuallyHidden } from '@mui/utils';
import styles from './Table.module.css';

const TableHeadComponent = (props) => {
    const {
        headCells,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        canSelectAll
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {canSelectAll &&
                    <TableCell padding="checkbox" className={styles.CheckboxCell}>
                        <Checkbox
                            color="primary"
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-labelledby': 'check-all' }}
                        />
                    </TableCell>
                }
                {headCells.map((headCell, index) => (
                    <TableCell key={headCell.id} align={'left'} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}
                    width={(headCell.width) ? headCell.width : 'auto'}className={styles.HeadCell}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell width="10%" align="left" padding="none" className={styles.HeadCellLast}/>
            </TableRow>
        </TableHead>
    );
};

TableHeadComponent.propTypes = {
    headCells: PropTypes.array.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    canSelectAll: PropTypes.bool
};

export default TableHeadComponent;