import axios from 'axios';
import { handleResponse, handleError } from './Response';

const BASE_URL = '/api';

const config = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
}

/** @param {string} resource */
const get = (resource) => {
    return axios
        .get(`${BASE_URL}/${resource}`, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource, id) => {
    return axios
        .get(`${BASE_URL}/${resource}/${id}`, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} query */
const getList = (resource, query) => {
    return axios
        .post(`${BASE_URL}/${resource}/list`, query, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {string} search */
const search = (resource, search) => {
    return axios
        .get(`${BASE_URL}/${resource}?search=${search}`, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model) => {
    return axios
        .post(`${BASE_URL}/${resource}`, model, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const put = (resource, model) => {
    return axios
        .put(`${BASE_URL}/${resource}`, model, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource, id) => {
    return axios
        .delete(`${BASE_URL}/${resource}/${id}`, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {string[]} ids */
const removeMany = (resource, ids) => {
    return axios
        .post(`${BASE_URL}/${resource}/remove-many`, ids, config)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const changeStatus = (resource, model) => {
    return axios
        .put(`${BASE_URL}/${resource}/change-status`, model, config)
        .then(handleResponse)
        .catch(handleError);
}

export const apiProvider = {
    get,
    getSingle,
    getList,
    search,
    post,
    put,
    remove,
    removeMany,
    changeStatus
};