import React from 'react';

import {
    Box,
    Button,
    Checkbox,
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    InputBase,
} from "@material-ui/core";
import { styled, alpha } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TableHead from '../shared/table/TableHeadComponent';

import CreateProductCategoryDialog from './CreateProductCategoryDialogComponent';

import { apiProductCategory } from '../../services/api/product/Category';
import tableStyles from '../shared/table/Table.module.css';
import { confirm } from 'react-confirm-box';
import { confirmOptions } from '../../services/common/Options';

const headCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'ID',
        width: '5%'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Nazwa',
    }
];

const Search = styled('div')(({ theme }) => ({
position: 'relative',
borderRadius: theme.shape.borderRadius,
backgroundColor: alpha(theme.palette.common.white, 0.15),
'&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
},
marginRight: theme.spacing(2),
marginLeft: 0,
width: '100%',
[theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
        width: '20ch',
        },
    },
}));

class ProductCategoriesComponent extends React.Component {
    constructor(props) {
        super();

        this.state = {
            order: 'asc',
            orderBy: 'Id',
            search: '',
            selected: [],
            page: 0,
            rowsPerPage: 5,
            rows: [],
            size: 0
        };

        this.timer = null;

        this.loadData();
    }

    loadData = () => {
        clearTimeout(this.timer);

        let query = {
            orderBy: this.state.orderBy,
            orderDestination: this.state.order.toUpperCase(),
            start: this.state.page * this.state.rowsPerPage,
            size: this.state.rowsPerPage,
            search: this.state.search
        };

        this.timer = setTimeout(async () => {
            let machines = await apiProductCategory.getList(query);

            this.setState({
                rows: machines.items,
                size: machines.length
            });
        }, 500);
    };

    clearFilters = () => {
        this.setState({
            search: ''
        }, this.loadData);
    }

    handleCreated = () => this.loadData();

    handleDelete = async (id) => {
        const result = await confirm("Czy na pewno chcesz usunąć wybrany wiersz?", confirmOptions);

        if (result) {
            await apiProductCategory.remove(id);
            this.loadData();
        }
    }

    handleDeleteSelected = async () => {
        const result = await confirm("Czy na pewno chcesz usunąć wybrane wiersze?", confirmOptions);

        if (result) {
            await apiProductCategory.removeMany(this.state.selected);
            this.loadData();
        }
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState({
                selected: this.state.rows.map((n) => n.id)
            });

            return;
        }
        this.setState({ selected: [] });
    };

    handleRequestSort = (event, property) => {
        this.setState({
            order: property === this.state.orderBy && this.state.order !== 'desc' ? 'desc' : 'asc',
            orderBy: property,
        }, this.loadData);
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        }, this.loadData);
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }, this.loadData);
    }

    handleCheckboxChange = (id, isChecked) => {
        if (isChecked)  {
            this.setState({
                selected: [id, ...this.state.selected]
            });
        } else {
            this.setState({
                selected: this.state.selected.filter((item) => item !== id)
            });
        }
    }

    labelDisplayedRows = ({ from, to, count }) => {
        return `${from}–${to} z ${count !== -1 ? count : `więcej niż ${to}`}`;
    }

    isSelected = (name) => this.state.selected.indexOf(name) !== -1;

    render() {
        return (
            <Grid>
                <Grid container spacing={1} className={tableStyles.TableMenu}>
                    <Grid item md={2}>
                        <h1>Kategorie produktów</h1>
                    </Grid>
                    <Grid item md={2}>
                        <CreateProductCategoryDialog onCreated={this.handleCreated} editMode={false} />
                    </Grid>
                    <Grid item md={2}>
                        <span className={tableStyles.Label}>Akcje grupowe</span>
                        <Button className={tableStyles.Button} startIcon={<DeleteOutlineIcon />} onClick={this.handleDeleteSelected}>
                            Usuń
                        </Button>
                    </Grid>
                    <Grid item md={2} />
                    <Grid item md={2}>
                    <Search className={tableStyles.Search}>
                        <SearchIconWrapper>
                        <SearchIcon className={tableStyles.Icon}/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Szukaj..."
                            inputProps={{ 'aria-label': 'search' }}
                            value={this.state.search}
                            onChange={(event) => {
                                this.setState({
                                    search: event.target.value
                                }, () => {
                                    if (this.state.search.length >= 2 || this.state.search.length === 0)
                                        this.loadData();
                                });
                            }}
                        />
                    </Search>
                    </Grid>
                    <Grid item md={12} className="d-flex justify-content-end">
                        <Button className={tableStyles.Button} startIcon={<CloseIcon />} onClick={this.clearFilters}>
                            Wyczyść filtry
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Box sx={{ width: '100%' }}>
                        <TableContainer>
                            <Table className={tableStyles.Table}>
                                <TableHead
                                    headCells={headCells}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    numSelected={this.state.selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    onRequestSort={this.handleRequestSort}
                                    rowCount={this.state.size}
                                    canSelectAll
                                />
                                <TableBody>
                                    {this.state.rows.map((row, index) => {
                                        const isItemSelected = this.isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow key={row.id} className={tableStyles.Row}>
                                                <TableCell padding="checkbox" className={tableStyles.CheckboxCell}>
                                                    <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} onChange={(event) => {
                                                        this.handleCheckboxChange(row.id, event.target.checked);
                                                    }}/>
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={`${tableStyles.ValueCell} ${tableStyles.ValueCellFirst}`}>
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left" padding="none" className={tableStyles.ValueCell}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right" padding="none" className={tableStyles.CrudCell}>
                                                    <CreateProductCategoryDialog onCreated={this.handleCreated} editMode={true} id={row.id} />
                                                    <DeleteOutlineIcon role="button" className={tableStyles.Button} onClick={async () => this.handleDelete(row.id)}/>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.size}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            labelDisplayedRows={this.labelDisplayedRows}
                            labelRowsPerPage={"Wierszy na stronę:"}
                        />
                    </Box>
                </Grid>
            </Grid>
        )
    };
}

export default ProductCategoriesComponent;