import ApiCore from '../utilities/ApiCore';

export const apiShop = new ApiCore({
    get: false,
    getSingle: true,
    getList: true,
    search: false,
    post: true,
    put: true,
    remove: true,
    removeMany: true,
    changeStatus: true,
    url: 'shop',
    plural: 'shop',
    single: 'shop'
});