import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';


export class Layout extends Component {
  constructor(props) {
    super();

    this.state = {
      isAuthenticated: localStorage.getItem('token') != null && localStorage.getItem('token') != ''
      && localStorage.getItem('token') != 'undefined'
    }
  }

  render () {
    if (this.state.isAuthenticated)
      return (
        <div>
          <NavMenu />
          <Container>
            {this.props.children}
          </Container>
        </div>
      );
    else return (
      <div>
        {this.props.children}
      </div>
    );
  }
}
