import React, { Component } from 'react';
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavbarText,
    NavItem,
    NavLink,
    Nav
} from 'reactstrap';
import {
    Tabs,
    Tab
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.css';
import LogoutIcon from '@mui/icons-material/Logout';

const pages = [
  {
    link: '/',
    name: 'Pulpit'
  },
  {
    link: '/products',
    name: 'Lista produktów'
  },
  {
    link: '/orders',
    name: 'Lista zamówień'
  },
  {
    link: '/coupons',
    name: 'Kody zniżkowe'
  },
  {
    link: '/categories',
    name: 'Kategorie'
  }
]

function NavMenu(props) {
    let location = useLocation();
    const [collapsed, setCollapsed] = React.useState(true);
    const [currentPage, setCurrentPage] = React.useState(location.pathname);
    const LoggedUser = localStorage.getItem('name');

    React.useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location]);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    const logout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }

    return (
        <header>
            <Container className="main-navbar">
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <NavbarBrand tag={Link} to="/" className="LogoSmall">
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <div>
                        <Nav fill>
                            {pages.map((page, i) => {
                                return (
                                    <NavItem key={i}>
                                        <NavLink tag={Link} className={`text-dark ${(page.link === currentPage) ? 'active' : ''}`} to={page.link}>
                                            {page.name}
                                            <div className="active-underline"></div>
                                        </NavLink>
                                    </NavItem>
                                )
                            })}
                            <NavItem>
                                <div className="user-bar">
                                    <Link to="account" className={(currentPage === '/account') ? 'active' : ''}>{LoggedUser}</Link>
                                </div>
                            </NavItem>
                            <NavItem className="logout-nav-item">
                                <LogoutIcon sx={{fontSize: 30, cursor: 'pointer'}} onClick={logout}/>
                            </NavItem>
                        </Nav>
                        </div>
                    </Collapse>
                </Navbar>
            </Container>
        </header>
    );
}

export default NavMenu;