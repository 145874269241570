import ApiCore from '../utilities/ApiCore';

export const apiLogin = new ApiCore({
    get: false,
    getSingle: false,
    getCustom: true,
    search: false,
    post: true,
    postCustom: true,
    put: false,
    putCustom: true,
    delete: false,
    url: 'login',
    plural: 'login',
    single: 'login'
});