import React from 'react';
import {
    Button,
    Menu,
    MenuItem} from "@material-ui/core";
import tableStyles from '../../shared/table/Table.module.css';

function ChangeStatusMenu(props) {
    const {
        items,
        update,
        onUpdated
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChangeStatus = async (status) => {
        await update(status);
        handleClose();
        onUpdated();
    }

    return (
      <div>
        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={tableStyles.Button}
        >
          Wybierz
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
            {items.map((item, index) => <MenuItem key={index} onClick={async () => await handleChangeStatus(item.id)}>{item.name}</MenuItem>)}
        </Menu>
      </div>
    );
}

export {
    ChangeStatusMenu
}