import React from 'react';
import { useForm } from 'react-hook-form';
import { apiLogin } from '../../services/api/accounts/Login';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import styles from './Login.module.css';

const RemindPassword = () => {
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {
        await apiLogin.postCustom('/remind-password', data);
        window.location.href = "/";
    };

    return (
        <Grid container>
            <Grid item xs={8}>
                <div className={styles.LoginBackground}></div>
            </Grid>
            <Grid item xs={4}>
                <Container component="main" maxWidth="sm" className={`${styles.LoginContainer} height100`}>
                    <Grid container direction="column">
                        <a className={styles.LogoSmall}></a>
                        <Grid>
                            <form onSubmit={handleSubmit(onSubmit)} className={styles.LoginForm}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <h1>Przypomnij hasło</h1>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Email" fullWidth required autoFocus {...register("email", { required: true })} /><br />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <button type="submit" className={styles.LoginButton}>Przypomnij</button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}

export default RemindPassword;