import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { apiCoupon } from '../../services/api/coupon/Coupon';

import TextField from '../shared/formControls/TextField'
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    MenuItem,
    InputAdornment,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import EditIcon from '@mui/icons-material/Edit';
import tableStyles from '../shared/table/Table.module.css';
import { couponTypes } from '../../configurations/coupon/couponTypes';
import { apiProduct } from '../../services/api/product/Product';
import { adjustDate } from '../../services/common/Dates';

const CreateCoupon = (props) => {
    const {
        onCreated,
        editMode,
        couponId
    } = props;

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState(couponTypes[0]);
    const [indefinitePeriod, setIndefinitePeriod] = React.useState(false);
    const [validityPeriodFrom, setValidityPeriodFrom] = React.useState('');
    const [validityPeriodTo, setValidityPeriodTo] = React.useState('');
    const [formRef, setFormRef] = React.useState('');
    const [products, setProducts] = React.useState([]);
    const [selectedProducts, setSelectedProducts] = React.useState([]);

    const handleClickOpen = async () => {
        setOpen(true);
        loadProducts();

        if (editMode)
            await loadData();
    }

    const loadData = async () => {
        let data = await apiCoupon.getSingle(couponId);

        if (data.coupon) {
            setType(couponTypes[data.coupon.type]);
            setValidityPeriodFrom(data.coupon.validityPeriodFrom);
            setValidityPeriodTo(data.coupon.validityPeriodTo);

            if (data.coupon.products.length > 0)
                setSelectedProducts(data.coupon.products);

            reset({
                code: data.coupon.code,
                discountAmount: data.coupon.discountAmount,
                limit: data.coupon.limit
            });
        }
    }

    const loadProducts = () => {
        apiProduct.getCustom(`/select`).then((data) => {
            setProducts(data.items);
        });
    }

    const handleClose = () => {
        setType(couponTypes[0]);
        setSelectedProducts([]);
        setValidityPeriodFrom('');
        setValidityPeriodTo('');
        reset({
            code: '',
            discountAmount: '',
            validityPeriodFrom: '',
            validityPeriodTo: '',
            limit: ''
        });
        setOpen(false);
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
    }

    const handleProductsChange = (event) => {
        setSelectedProducts(event.target.value);
    }

    const handleValidityPeriodFromChange = (value) => {
        setValidityPeriodFrom(value);
    }

    const handleValidityPeriodToChange = (value) => {
        setValidityPeriodTo(value);
    }

    const submitForm = () => {
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }

    const handleFormSubmit = async (data) => {
        data.discountAmount = data.discountAmount.toString().replace('.',',');
        data.type = type.id;
        data.products = selectedProducts;
        data.validityPeriodFrom = adjustDate(new Date(validityPeriodFrom));
        data.validityPeriodTo = adjustDate(new Date(validityPeriodTo));
        data.indefinitePeriod = indefinitePeriod;

        let result = editMode ? await apiCoupon.put(couponId, data)
                              : await apiCoupon.post(data);

        if (result) {
            onCreated();
            handleClose();
        }
        else {
            alert("Błąd");
        }
    }

    return (
        <div className="d-inline" style={{flex: '0 1 125px'}}>
            {
                editMode
                ? <EditIcon role="button" className={tableStyles.Button} onClick={handleClickOpen} />
                : <button className="btn btn-link" onClick={handleClickOpen}>Dodaj nowy</button>
            }
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>Dodaj kupon</DialogTitle>
                <DialogContent>
                    <form ref={ref => setFormRef(ref)} onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.code?.message}</span>}
                                        className={(errors?.code) ? 'with-error' : ''}
                                        margin="dense"
                                        id="code"
                                        label="Treść kodu"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("code", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.limit?.message}</span>}
                                        className={(errors?.limit) ? 'with-error' : ''}
                                        margin="dense"
                                        id="limit"
                                        label="Limit"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("limit", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.type?.message}</span>}
                                        className={(errors?.type) ? 'with-error' : ''}
                                        select
                                        margin="dense"
                                        id="type"
                                        label="Rodzaj kuponu"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={type}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("type", { required: 'To pole jest wymagane' })}
                                        onChange={handleTypeChange}
                                    >
                                    {
                                        couponTypes.map((type, index) => <MenuItem key={index} className="menu-item-select" value={type}>{type.name}</MenuItem>)
                                    }
                                    </TextField>
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.discountAmount?.message}</span>}
                                        className={(errors?.discountAmount) ? 'with-error' : ''}
                                        margin="dense"
                                        id="discountAmount"
                                        label="Zniżka"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{type.symbol}</InputAdornment>,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("discountAmount", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form" direction="column">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            required
                                            inputFormat="dd.MM.yyyy"
                                            maxDate={validityPeriodTo}
                                            margin="dense"
                                            id="validityPeriodFrom"
                                            label="Okres aktywności (od)"
                                            fullWidth
                                            variant="outlined"
                                            renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                                            value={validityPeriodFrom}
                                            onChange={handleValidityPeriodFromChange}
                                        />
                                    </LocalizationProvider>
                                    <FormControlLabel control={<Checkbox checked={indefinitePeriod} onChange={(event) => setIndefinitePeriod(event.target.checked)} />} label="Na czas nieokreślony" sx={{marginLeft: 0}}/>
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    {!indefinitePeriod &&
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                inputFormat="dd.MM.yyyy"
                                                minDate={validityPeriodFrom}
                                                margin="dense"
                                                id="validityPeriodTo"
                                                label="Okres aktywności (do)"
                                                fullWidth
                                                variant="outlined"
                                                renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                                                value={validityPeriodTo}
                                                onChange={handleValidityPeriodToChange}
                                            />
                                        </LocalizationProvider>
                                    }
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                </Grid>
                                {(products && (type.id === 0 || type.id === 2)) &&
                                <Grid item md={12} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.products?.message}</span>}
                                        className={(errors?.products) ? 'with-error' : ''}
                                        select
                                        margin="dense"
                                        id="products"
                                        label="Produkty"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={selectedProducts}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("products", { required: 'To pole jest wymagane' })}
                                        onChange={handleProductsChange}
                                        SelectProps={{
                                            multiple: true
                                        }}
                                    >
                                        {products.map((product, index) => <MenuItem className="menu-item-select" key={index} value={product.id}>{product.name}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                }
                            </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className="button filled-button" onClick={handleClose}>Anuluj</Button>
                    <Button className="button outline-button" onClick={submitForm}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CreateCoupon.propTypes = {
    onCreated: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    couponId: PropTypes.number
};

export default CreateCoupon;