const numberWithTwoPlaces = (number) => {
    return parseFloat(number).toFixed(2);
}

const getNumberString = (number) => {
    return number.toString().replace('.', ',');
}

export {
    numberWithTwoPlaces,
    getNumberString
}