import React, { Component, useEffect } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import Login from './components/login/LoginComponent';
import Products from './components/products/ProductsComponent';
import ProductCategories from './components/products/ProductCategoriesComponent';
import SetPassword from './components/login/SetPasswordComponent';
import Orders from './components/orders/OrdersComponent';
import { useLocation, useHistory } from 'react-router-dom';

import './fonts/fonts.css';
import './custom.css'
import Coupons from './components/coupons/Coupons';
import RemindPassword from './components/login/RemindPassword';
import Account from './components/account/AccountComponent';

const isAuthenticated = localStorage.getItem('token') != null && localStorage.getItem('token') != ''
    && localStorage.getItem('token') != 'undefined';

const authRoutes = [
    '/',
    '/categories',
    '/products',
    '/orders',
    '/coupons',
    '/account'
];

const App = () => {
    const location = useLocation();
    const history = useHistory();
    const buildLayout = authRoutes.indexOf(location.pathname) >= 0;

    useEffect(() => {
        if (!isAuthenticated && authRoutes.indexOf(location.pathname) >= 0) {
            history.push('/login');
        }
    }, [history, location.pathname]);

    return (
        <>
            {buildLayout &&
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/categories' component={ProductCategories} />
                    <Route exact path='/products' component={Products} />
                    <Route exact path='/orders' component={Orders} />
                    <Route exact path='/coupons' component={Coupons} />
                    <Route exact path='/account' component={Account} />
                </Layout>
            }
            <Route exact path='/login' component={Login} />
            <Route exact path='/remind-password' component={RemindPassword} />
            <Route exact path='/set-password/:token' component={SetPassword} />
        </>
    );
};

export default App;

