import ApiCore from '../utilities/ApiCore';

export const apiProduct = new ApiCore({
    get: false,
    getSingle: true,
    getList: true,
    getCustom: true,
    search: false,
    post: true,
    put: true,
    remove: true,
    removeMany: true,
    changeStatus: true,
    url: 'product',
    plural: 'product',
    single: 'product'
});