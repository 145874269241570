import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { apiProduct } from '../../services/api/product/Product';

import TextField from '../shared/formControls/TextField'
import {
    Button,
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    MenuItem} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import tableStyles from '../shared/table/Table.module.css';
import { unitsList } from '../../configurations/units/unitsList';
import { numberWithTwoPlaces } from '../../services/common/Numbers';

const CreateProductDialog = (props) => {
    const {
        onCreated,
        editMode,
        productId,
        categories
    } = props;

    const { register, handleSubmit, reset, getValues, setValue, formState: { errors } } = useForm();

    const [open, setOpen] = React.useState(false);
    const [category, setCategory] = React.useState('');
    const [status, setStatus] = React.useState(1);
    const [formRef, setFormRef] = React.useState('');
    const [selectedPhoto, setSelectedPhoto] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [products, setProducts] = React.useState([]);
    const [variants, setVariants] = React.useState([]);
    const [descriptionLength, setDescriptionLength] = React.useState(0);
    const [unit, setUnit] = React.useState(unitsList[0]);
    const [existedImage, setExistedImage] = React.useState('');
    const descriptionLengthLimit = 1000;

    let timer = null;

    const handleClickOpen = async () => {
        setOpen(true);

        if (editMode)
            await loadData();
    }

    const loadData = async () => {
        let data = await apiProduct.getSingle(productId);

        if (data.product) {
            setExistedImage(data.product.photoPath);
            setCategory(data.product.categoryId);
            setStatus(data.product.status);

            if (data.product.variants.length > 0)
                setVariants(data.product.variants);

            loadProducts(data.product.categoryId);

            reset({
                categoryId: data.product.categoryId,
                name: data.product.name,
                variantName: data.product.variantName,
                netPrice: data.product.netPrice,
                vat: data.product.vat,
                grosPrice: data.product.grosPrice,
                unit: data.product.unit,
                quantity: data.product.quantity,
                status: data.product.status,
                description: data.product.description
            });
        }
    }

    const loadProducts = (id) => {
        apiProduct.getCustom(`/by-category/${id}`).then((data) => {
            setProducts(data.products.filter((product) => product.id !== productId));
        });
    }

    const handleClose = () => {
        setCategory('');
        setStatus(1);
        setProducts([]);
        setVariants([]);
        setExistedImage('');
        reset();
        setOpen(false);
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    }

    const handleCategoryChange = (event) => {
        loadProducts(event.target.value);
        setCategory(event.target.value);
    }

    const handleVariantsChange = (event) => {
        setVariants(event.target.value);
    }

    const handleUnitChange = (event) => {
        setUnit(event.target.value);
    }

    const submitForm = () => {
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }

    const handleNetPriceChange = (event) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            let vat = Number(getValues('vat'));

            let netPrice = event.target.valueAsNumber;
            let grosPrice = numberWithTwoPlaces(netPrice * (1 + (vat / 100)));
            let currentGrosPrice = Number(getValues('grosPrice'));

            if (grosPrice !== currentGrosPrice)
                setValue('grosPrice', grosPrice);
        }, 1000);
    }

    const handleGrosPriceChange = (event) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            let vat = Number(getValues('vat'));

            let grosPrice = event.target.valueAsNumber;
            let netPrice = numberWithTwoPlaces(grosPrice / (1 + (vat / 100)));
            let currentNetPrice = Number(getValues('netPrice'));

            if (netPrice !== currentNetPrice)
                setValue('netPrice', netPrice);
        }, 1000);
    }

    const handleVATChange = (event) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            let vat = event.target.valueAsNumber;

            let netPrice = Number(getValues('netPrice'));
            let grosPrice = Number(getValues('grosPrice'));

            if (netPrice)
                setValue('grosPrice', numberWithTwoPlaces(netPrice * (1 + (vat / 100))));
            else if (grosPrice)
                setValue('netPrice', numberWithTwoPlaces(grosPrice / (1 + (vat / 100))))
        }, 1000);
    }

    const handleDescriptionChange = (event) => {
        setDescriptionLength(event.target.value.length);
    }

    const handleFormSubmit = async (data) => {
        data.netPrice = data.netPrice.toString().replace('.',',');
        data.grosPrice = data.grosPrice.toString().replace('.',',');
        data.categoryId = category;
        data.status = status;
        data.variants = variants;
        data.unit = unit;
        data.photo = selectedPhoto;
        data.file = selectedFile;

        var formData = new FormData();

        for (var key in data) {
            formData.append(key, data[key]);
        }

        let result = editMode ? await apiProduct.put(productId, formData)
                              : await apiProduct.post(formData);

        if (result) {
            onCreated();
            handleClose();
        }
        else {
            alert("Błąd");
        }
    }

    const handlePhotoChange = (event) => {
        setSelectedPhoto(event.target.files[0]);
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    return (
        <div className="d-inline">
            {
                editMode
                ? <EditIcon role="button" className={tableStyles.Button} onClick={handleClickOpen} />
                : <button className="btn btn-link" onClick={handleClickOpen}>Dodaj nowy</button>
            }
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogTitle>Dodaj produkt</DialogTitle>
                <DialogContent>
                    <form ref={ref => setFormRef(ref)} onSubmit={handleSubmit(handleFormSubmit)}>
                    <Grid container spacing={2}>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.name?.message}</span>}
                                        className={(errors?.name) ? 'with-error' : ''}
                                        margin="dense"
                                        id="name"
                                        label="Nazwa"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.variantName?.message}</span>}
                                        className={(errors?.variantName) ? 'with-error' : ''}
                                        margin="dense"
                                        id="variantName"
                                        label="Nazwa wariantu"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        {...register("variantName", { required: 'To pole jest wymagane' })}
                                    />
                                </Grid>
                                {(categories && categories.length > 0) &&
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors && !category ? 'To pole jest wymagane' : ''}</span>}
                                        className={(errors && !category) ? 'with-error' : ''}
                                        select
                                        margin="dense"
                                        id="categoryId"
                                        label="Kategoria"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={category}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("categoryId", { required: true })}
                                        onChange={handleCategoryChange}
                                    >
                                        {categories.map((category, index) => <MenuItem className="menu-item-select" key={index} value={category.id}>{category.name}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                }
                                <Grid item md={6} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        select
                                        margin="dense"
                                        id="status"
                                        label="Status"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={status}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("status", { required: true })}
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem className="menu-item-select" value={1}>Dostępny</MenuItem>
                                        <MenuItem className="menu-item-select" value={0}>Niedostępny</MenuItem>
                                        <MenuItem className="menu-item-select" value={2}>Nieaktywny</MenuItem>
                                    </TextField>
                                </Grid>
                                {(products) &&
                                <Grid item md={12} xs={12} className="modified-form">
                                    <TextField
                                        select
                                        margin="dense"
                                        id="variants"
                                        label="Warianty"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={variants}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("variants")}
                                        onChange={handleVariantsChange}
                                        SelectProps={{
                                            multiple: true
                                        }}
                                    >
                                        {products.map((product, index) => <MenuItem className="menu-item-select" key={index} value={product.id}>{product.name}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                }
                                <Grid item md={2} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.netPrice?.message}</span>}
                                        className={(errors?.netPrice) ? 'with-error' : ''}
                                        margin="dense"
                                        id="netPrice"
                                        label="Cena netto"
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        {...register("netPrice", { required: 'Wymagane pole' })}
                                        onChange={handleNetPriceChange}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.vat?.message}</span>}
                                        className={(errors?.vat) ? 'with-error' : ''}
                                        margin="dense"
                                        id="vat"
                                        label="Stawka VAT"
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        {...register("vat", { required: 'Wymagane pole' })}
                                        onChange={handleVATChange}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.grosPrice?.message}</span>}
                                        className={(errors?.grosPrice) ? 'with-error' : ''}
                                        margin="dense"
                                        id="grosPrice"
                                        label="Cena brutto"
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        {...register("grosPrice", { required: 'Wymagane pole' })}
                                        onChange={handleGrosPriceChange}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12} className="modified-form">
                                    <TextField
                                        select
                                        required
                                        margin="dense"
                                        id="unit"
                                        label="Jednostka"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={unit}
                                        InputLabelProps={{ shrink: true }}
                                        {...register("unit")}
                                        onChange={handleUnitChange}>
                                        {unitsList.map((unit, index) => <MenuItem className="menu-item-select" key={index} value={unit}>{unit}</MenuItem>)}
                                    </TextField>
                                </Grid>
                                <Grid item md={3} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={<span className="text-danger">{errors?.quantity?.message}</span>}
                                        className={(errors?.quantity) ? 'with-error' : ''}
                                        margin="dense"
                                        id="quantity"
                                        label="Ilość"
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        {...register("quantity", { required: 'Wymagane pole' })}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12} className="modified-form">
                                    <TextField
                                        required
                                        helperText={
                                            <div>
                                                <span>Wykorzystane znaki {descriptionLength} z dostępnych {descriptionLengthLimit}</span>
                                                <span className="text-danger"><br/>{errors?.description?.message}</span>
                                            </div>
                                        }
                                        className={(errors?.description) ? 'with-error' : ''}
                                        multiline
                                        rows="3"
                                        margin="dense"
                                        id="description"
                                        label="Opis"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ maxLength: descriptionLengthLimit }}
                                        {...register("description", { required: 'To pole jest wymagane' })}
                                        onChange={handleDescriptionChange}
                                    />
                                </Grid>
                                <Grid item md={12} className="file-upload-input">
                                    <span className="file-upload-label">Zdjęcie</span>
                                    <input
                                        type="file"
                                        className="form-control file-with-background"
                                        multiple=""
                                        onChange={handlePhotoChange}
                                        style={(editMode) ? {
                                            backgroundImage: `url(../${existedImage})`
                                        } : null}
                                    />
                                    <small>Preferowana wielkość zdjęcia to 400px szerokości i 400px wysokości</small>
                                </Grid>
                                <Grid item md={12} className="file-upload-input">
                                    <span className="file-upload-label">Plik</span>
                                    <input type="file" className="form-control" multiple="" onChange={handleFileChange}/>
                                </Grid>
                            </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className="button filled-button" onClick={handleClose}>Anuluj</Button>
                    <Button className="button outline-button" onClick={submitForm}>Zapisz</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CreateProductDialog.propTypes = {
    onCreated: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    productId: PropTypes.number,
    categories: PropTypes.array
};

export default CreateProductDialog;