import { Button, Grid, MenuItem, TextField } from "@mui/material";
import css from './Account.module.css';
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { apiShop } from "../../services/api/shop/Shop";

const Account = () => {
    const shopId = localStorage.getItem('shopId');
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [formRef, setFormRef] = useState('');
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [shopMachines, setShopMachines] = useState([]);

    const getData = useCallback(async () => {
        let data = await apiShop.getSingle(shopId);

        if (data.shop) {
            setCategory(Number(data.shop.categoryId));
            setCategories(data.shop.categories);
            setShopMachines(data.shop.shopMachines);

            reset({
                categoryId: data.shop.categoryId,
                name: data.shop.name,
                street: data.shop.street,
                buildingNumber: data.shop.buildingNumber,
                premisesNumber: data.shop.premisesNumber,
                postalCode: data.shop.postalCode,
                city: data.shop.city,
                phoneNumber: data.shop.phoneNumber,
                email: data.shop.email,
                description: data.shop.description,
                status: data.shop.status,
                companyName: data.shop.companyName,
                nip: data.shop.nip,
                regon: data.shop.regon,
                companyStreet: data.shop.companyStreet,
                companyBuildingNumber: data.shop.companyBuildingNumber,
                companyPremisesNumber: data.shop.companyPremisesNumber,
                companyPostalCode: data.shop.companyPostalCode,
                companyCity: data.shop.companyCity,
                companyPhoneNumber: data.shop.companyPhoneNumber,
                companyEmail: data.shop.companyEmail
            });
        }
    }, [shopId, reset]);

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    }

    const submitForm = () => {
        formRef.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }

    const handleFormSubmit = async (data) => {
        const formData = new FormData();
        formData.append('categoryId', category);
        formData.append('name', data.name);
        formData.append('street', data.street);
        formData.append('buildingNumber', data.buildingNumber);
        formData.append('premisesNumber', data.premisesNumber);
        formData.append('postalCode', data.postalCode);
        formData.append('city', data.city);
        formData.append('phoneNumber', data.phoneNumber);
        formData.append('email', data.email);

        formData.append('companyName', data.companyName ?? '');
        formData.append('nip', data.nip ?? '');
        formData.append('regon', data.regon ?? '');
        formData.append('companyStreet', data.companyStreet ?? '');
        formData.append('companyBuildingNumber', data.companyBuildingNumber ?? '');
        formData.append('companyPremisesNumber', data.companyPremisesNumber ?? '');
        formData.append('companyPostalCode', data.companyPostalCode ?? '');
        formData.append('companyCity', data.companyCity ?? '');
        formData.append('companyPhoneNumber', data.companyPhoneNumber ?? '');
        formData.append('companyEmail', data.companyEmail ?? '');

        let result = await apiShop.put(shopId, formData);

        if (result) {
        }
        else {
            alert("Błąd");
        }
    }

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Grid>
            <Grid container spacing={1} className={css.header}>
                <h1>Twój profil</h1>
                <div className={css.machines}>
                    <h3>Moje maszyny: <span className={css.names}>{shopMachines.join(', ')}</span></h3>
                </div>
            </Grid>
            <form ref={ref => setFormRef(ref)} onSubmit={handleSubmit(handleFormSubmit)} className={css.form}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h3>Dane firmy</h3>
                    </Grid>
                    <Grid item md={12} xs={12} className="modified-form">
                        <TextField
                            autoFocus
                            margin="dense"
                            id="companyName"
                            label="Nazwa firmy"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyName", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            margin="dense"
                            id="nip"
                            label="Nip"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("nip", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            margin="dense"
                            id="regon"
                            label="Regon"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("regon", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            margin="dense"
                            id="companyStreet"
                            label="Ulica"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyStreet", { required: false })}
                        />
                    </Grid>
                    <Grid item md={3} xs={6} className="modified-form">
                        <TextField
                            margin="dense"
                            id="companyBuildingNumber"
                            label="Numer budynku"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyBuildingNumber", { required: false })}
                        />
                    </Grid>
                    <Grid item md={3} xs={6} className="modified-form">
                        <TextField
                            margin="dense"
                            id="companyPremisesNumber"
                            label="Numer lokalu"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyPremisesNumber", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            margin="dense"
                            id="companyPostalCode"
                            label="Kod pocztowy"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyPostalCode", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            margin="dense"
                            id="companyCity"
                            label="Miasto"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyCity", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            margin="dense"
                            id="companyPhoneNumber"
                            label="Numer telefonu"
                            type="phone"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyPhoneNumber", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            margin="dense"
                            id="companyEmail"
                            label="Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("companyEmail", { required: false })}
                        />
                    </Grid>
                    <hr/>
                    <Grid item xs={12}>
                        <h3>Dane sklepu</h3>
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            required
                            helperText={<span className="text-danger">{errors?.name?.message}</span>}
                            className={(errors?.name) ? 'with-error' : ''}
                            margin="dense"
                            id="name"
                            label="Nazwa"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("name", { required: 'To pole jest wymagane' })}
                        />
                    </Grid>
                    {(categories && categories.length > 0) &&
                        <Grid item md={6} xs={12} className="modified-form">
                            <TextField
                                required
                                helperText={<span className="text-danger">{errors && !category ? 'To pole jest wymagane' : ''}</span>}
                                className={(errors && !category) ? 'with-error' : ''}
                                select
                                margin="dense"
                                id="categoryId"
                                label="Kategoria"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={category}
                                InputLabelProps={{ shrink: true }}
                                {...register("categoryId", { required: true })}
                                onChange={handleCategoryChange}
                            >
                                {categories.map((category, index) => <MenuItem className="menu-item-select" key={index} value={category.id}>{category.name}</MenuItem>)}
                            </TextField>
                        </Grid>
                    }
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            required
                            helperText={<span className="text-danger">{errors?.street?.message}</span>}
                            className={(errors?.street) ? 'with-error' : ''}
                            margin="dense"
                            id="street"
                            label="Ulica"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("street", { required: 'To pole jest wymagane' })}
                        />
                    </Grid>
                    <Grid item md={3} xs={6} className="modified-form">
                        <TextField
                            required
                            helperText={<span className="text-danger">{errors?.buildingNumber?.message}</span>}
                            className={(errors?.buildingNumber) ? 'with-error' : ''}
                            margin="dense"
                            id="buildingNumber"
                            label="Numer budynku"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("buildingNumber", { required: 'To pole jest wymagane' })}
                        />
                    </Grid>
                    <Grid item md={3} xs={6} className="modified-form">
                        <TextField
                            margin="dense"
                            id="premisesNumber"
                            label="Numer lokalu"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("premisesNumber", { required: false })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            required
                            helperText={<span className="text-danger">{errors?.postalCode?.type === 'required' ? 'To pole jest wymagane' : ''}</span>}
                            className={(errors?.postalCode) ? 'with-error' : ''}
                            margin="dense"
                            id="postalCode"
                            label="Kod pocztowy"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("postalCode", { required: true })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            required
                            helperText={<span className="text-danger">{errors?.city?.message}</span>}
                            className={(errors?.city) ? 'with-error' : ''}
                            margin="dense"
                            id="city"
                            label="Miasto"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("city", { required: 'To pole jest wymagane' })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            required
                            helperText={<span className="text-danger">{errors?.phoneNumber?.message}</span>}
                            className={(errors?.phoneNumber) ? 'with-error' : ''}
                            margin="dense"
                            id="phoneNumber"
                            label="Numer telefonu"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("phoneNumber", {
                                required: 'To pole jest wymagane',
                                pattern: {
                                    value: /^\+[1-9]{1}[0-9]{9,14}$/,
                                    message: 'Proszę wprowadzić prawidłowy numer telefonu',
                                }
                            })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12} className="modified-form">
                        <TextField
                            required
                            helperText={<span className="text-danger">{errors?.email?.message}</span>}
                            className={(errors?.email) ? 'with-error' : ''}
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            {...register("email", {
                                required: 'To pole jest wymagane',
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Proszę wprowadzić prawidłowy adres email',
                                }
                            })}
                        />
                    </Grid>
                </Grid>
                <div className={css.buttonContainer}>
                    <Button onClick={submitForm}>Zapisz</Button>
                </div>
            </form>
        </Grid>
    )
}

export default Account;