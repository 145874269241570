import React, { Component } from 'react';
import { apiOrder } from '../../services/api/order/Order';
import {
    Button,
    Checkbox,
    Grid} from "@material-ui/core";
import css from './Orders.module.css';
import tableStyles from '../shared/table/Table.module.css';
import { Search, SearchIconWrapper, StyledInputBase, StyledInputNumber, CategoryFilterMenu } from '../shared/formControls/Filters';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import OrderDetails from './OrderDetails';
import { ChangeStatusMenu } from '../shared/formControls/Actions';

const statuses = [
    {
        id: 0,
        name: 'Przyjęto zamówienie',
        color: '#FC9B00'
    },
    {
        id: 1,
        name: 'W trakcie realizacji',
        color: '#FC9B00'
    },
    {
        id: 2,
        name: 'Gotowe do wydania',
        color: '#FC9B00'
    },
    {
        id: 3,
        name: 'Gotowe do odbioru',
        color: '#FC9B00'
    },
    {
        id: 4,
        name: 'Wysłane',
        color: '#FC9B00'
    },
    {
        id: 5,
        name: 'Zakończone',
        color: '#FC9B00'
    },
    {
        id: 6,
        name: 'Anulowane',
        color: '#FC9B00'
    },
]

export default class OrdersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'desc',
            orderBy: 'Id',
            search: '',
            priceFrom: '',
            priceTo: '',
            statuses: statuses.map((status) => status.id),
            products: '',
            selected: [],
            page: 0,
            rowsPerPage: 8,
            haveNext: false,
            rows: [],
            size: 0,
            productsList: [],
            current: null
        };

        this.timer = null;
        this.loadData();

        this.handlePreviousPageClick = this.handlePreviousPageClick.bind(this);
        this.handleNextPageClick = this.handleNextPageClick.bind(this);
    }

    loadData = () => {
        clearTimeout(this.timer);

        let query = {
            orderBy: this.state.orderBy,
            orderDestination: this.state.order.toUpperCase(),
            start: this.state.page * this.state.rowsPerPage,
            size: this.state.rowsPerPage,
            search: this.state.search,
            priceFrom: this.state.priceFrom,
            priceTo: this.state.priceTo,
            statuses: this.state.statuses,
            products: (this.state.products) ? [this.state.products.id] : []
        };

        this.timer = setTimeout(async () => {
            let orders = await apiOrder.getList(query);

            let haveNext = ((this.state.page + 1) * this.state.rowsPerPage) < orders.length;

            this.setState({
                haveNext,
                rows: orders.items,
                size: orders.length,
                selected: [],
                productsList: orders.products
            });
        }, 500);
    }

    clearFilters = () => {
        this.setState({
            search: '',
            priceFrom: '',
            priceTo: '',
            statuses: statuses.map((status) => status.id),
            products: '',
        }, this.loadData);
    }

    handleDetailsClick = (id) => {
        clearTimeout(this.timer);

        this.timer = setTimeout(async () => {
            let data = await apiOrder.getSingle(id);

            this.setState({
                current: data.order
            });
        });
    }

    handleSelectAllClick = (event) => {
        if (event.target.checked) {
            this.setState({
                selected: this.state.rows.map((n) => n.id)
            });
            return;
        }

        this.setState({ selected: [] });
    };

    handleCheckboxChange = (id, isChecked) => {
        if (isChecked)  {
            this.setState({
                selected: [id, ...this.state.selected]
            });
        } else {
            this.setState({
                selected: this.state.selected.filter((item) => item !== id)
            });
        }
    }

    handleChangeStatus = async (status) => {
        await apiOrder.putCustom('/setStatusMany', {ids: this.state.selected, status});
    }

    handlePreviousPageClick = () => {
        this.setState({
            page: this.state.page - 1
        }, this.loadData);
    }

    handleNextPageClick = () => {
        this.setState({
            page: this.state.page + 1
        }, this.loadData);
    }

    setStatusFilter = (status) => {
        let statuses = [...this.state.statuses];

        if (statuses.indexOf(status) >= 0)
            statuses = statuses.filter((s) => s !== status);
        else
            statuses.push(status);

        this.setState({
            statuses
        }, this.loadData);
    }

    render() {
        return (
            <div>
                <Grid container spacing={1} className={tableStyles.TableMenu}>
                    <Grid item md={12} className="d-flex align-items-baseline justify-content-between">
                        <div className="d-flex flex-row">
                            <h1>Lista zamówień</h1>
                            <div style={{marginLeft: '5px'}} className="d-flex flex-row">
                                <span className={tableStyles.Label}>Ustaw status dla zaznaczonych:</span>
                                <ChangeStatusMenu items={statuses} update={this.handleChangeStatus} onUpdated={this.loadData} />
                            </div>
                            <Search className={tableStyles.Search}>
                                <SearchIconWrapper>
                                <SearchIcon className={tableStyles.Icon}/>
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Szukaj..."
                                    inputProps={{ 'aria-label': 'search' }}
                                    value={this.state.search}
                                    onChange={(event) => {
                                        this.setState({
                                            search: event.target.value
                                        }, () => {
                                            if (this.state.search.length >= 2 || this.state.search.length === 0)
                                                this.loadData();
                                        });
                                    }}
                                />
                            </Search>
                        </div>
                        <div className="d-flex flex-row">
                            <div style={{marginLeft: '5px'}} className="d-flex flex-row">
                                <span className={tableStyles.Label}>Produkty:</span>
                                <CategoryFilterMenu categories={this.state.productsList} selected={this.state.products} onUpdated={(product) => {
                                    this.setState({
                                        products: product
                                    }, this.loadData);
                                }} />
                            </div>
                            <div>
                                <span className={tableStyles.Label}>Kwota zamówienia:</span>
                                <StyledInputNumber
                                    placeholder="Od"
                                    inputProps={{ 'aria-label': 'search', 'type': 'number' }}
                                    value={this.state.priceFrom}
                                    onChange={(event) => {
                                        this.setState({
                                            priceFrom: event.target.value
                                        }, this.loadData);
                                    }}
                                />
                                <StyledInputNumber
                                    placeholder="Do"
                                    inputProps={{ 'aria-label': 'search', 'type': 'number' }}
                                    value={this.state.priceTo}
                                    onChange={(event) => {
                                        this.setState({
                                            priceTo: event.target.value
                                        }, this.loadData);
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={12} className="d-flex justify-content-end">
                        <Button className={tableStyles.Button} startIcon={<CloseIcon />} onClick={this.clearFilters}>
                            Wyczyść filtry
                        </Button>
                    </Grid>
                </Grid>
                <div className={`d-flex flex-row justify-content-between mt-4`}>
                    <Checkbox color="primary" onChange={this.handleSelectAllClick} checked={this.state.rows?.length > 0 && this.state.rows.length === this.state.selected.length} />
                    <div className="d-flex align-items-center">
                        <span className={css.StatusLabel}>Pokaż statusy</span>
                        {statuses.map((status, index) => {
                            return (
                                <span key={index}
                                      className={`${css.StatusFilter} ${(this.state.statuses.indexOf(status.id) >= 0) ? css.Active : ''}`}
                                      onClick={() => this.setStatusFilter(status.id)}
                                >{status.name}</span>
                            )
                        })}
                    </div>
                </div>
                <Grid container spacing={2} className="mt-3">
                    {this.state.rows?.map((row) => {
                        if (this.state.current != null && this.state.current.id !== row.id)
                            return <></>;

                        const isItemSelected = this.state.selected.indexOf(row.id) !== -1;

                        return (
                            <Grid key={row.id} item md={(this.state.current) ? 12 : 3}>
                                {!this.state.current
                                    ? <div className={css.OrderBox}>
                                        <div>
                                            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                                                <Checkbox color="primary" className={css.Checkbox} checked={isItemSelected} onChange={(event) => {
                                                        this.handleCheckboxChange(row.id, event.target.checked) }}/>
                                                <span className={css.Id}>{row.id}</span>
                                            </div>
                                            {row.products.map((product, index) => (
                                                <p
                                                    key={index}
                                                    className={css.Product}
                                                    onClick={() => this.handleDetailsClick(row.id)}
                                                    style={{cursor: 'pointer'}}
                                                >
                                                    {product}
                                                </p>
                                            ))}
                                        </div>
                                        <div
                                            onClick={() => this.handleDetailsClick(row.id)}
                                            style={{cursor: 'pointer'}}
                                        >
                                            <p className={css.Total}>{parseFloat(row.total).toFixed(2).toString().replace('.',',')} zł</p>
                                            <div className="d-flex flex-row justify-content-between align-items-center mb-2">
                                                <div className={css.StatusContainer}>
                                                    <span className={css.Status}>{row.status}</span>
                                                    <span className={css.Log}>{row.log}</span>
                                                </div>
                                                <button className={css.Button} onClick={() => this.handleDetailsClick(row.id)}>Zmień</button>
                                            </div>
                                        </div>
                                    </div>
                                    : <OrderDetails order={this.state.current} onBackClick={() => this.setState({current: null})} onChange={this.loadData} />
                                }
                            </Grid>
                        );
                    })}
                </Grid>
                {!this.state.current &&
                <div className={`${css.PaginationContainer} ${(this.state.page === 0 && this.state.haveNext) ? css.FlexEnd : ''}`}>
                    {this.state.page > 0 &&
                        <Button onClick={this.handlePreviousPageClick}>Poprzednie</Button>
                    }
                    {this.state.haveNext &&
                        <Button onClick={this.handleNextPageClick}>Następne</Button>
                    }
                </div>
                }
            </div>
        );
    }
}